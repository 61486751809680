<template>
  <TransitionRoot
    as="template"
    :show="isVisible()"
  >
    <Dialog
      class="relative z-10"
      @close="hideComponent()"
    >
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-2 sm:p-0 text-center sm:items-center">
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel class="relative transform overflow-hidden rounded-sm bg-black px-2 sm:px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 w-full max-w-2xl sm:p-6">
              <div class="absolute right-0 top-0 pr-4 pt-4">
                <button
                  type="button"
                  class="rounded-md bg-black text-white hover:text-gray-500 focus:outline-none"
                  @click="hideComponent"
                >
                  <span class="sr-only">Close</span>
                  <XMarkIcon
                    class="h-9 w-9"
                    aria-hidden="true"
                  />
                </button>
              </div>
              <div class="mx-2">
                <iframe
                  class="mx-auto block w-full h-[1120px] xs:h-[1020px] sm:h-[872px]"
                  xml="lang"
                  src="https://app.glofox.com/portal/#/branch/66f32a78f06e18976f0e20b3/lead-register/membership/66f5b1d6d856102cb0051ed5/plan/1727377842510"
                  scrolling="yes"
                  frameborder="0"
                />
              </div>
              <div class="flex justify-center underline text-white">
                powered by<a
                  class="no-underline"
                  href="https://www.glofox.com"
                ><b>&nbsp;Glofox</b></a>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
  import { Dialog, DialogPanel, TransitionRoot, TransitionChild } from "@headlessui/vue"
  import { XMarkIcon } from "@heroicons/vue/24/outline"

  import { useComponentVisibility } from "~/composables/useComponentVisibility"

  const { isVisible, hideComponent } = useComponentVisibility( "trial-dialog" )
</script>
