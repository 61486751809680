<template>
  <div class="bg-black">
    <header class="site-header">
      <nav
        class="mx-auto flex max-w-7xl items-center align-middle justify-between p-3 md:p-4"
        aria-label="Global"
      >
        <nuxt-link
          to="/"
          class="-m-1.5 p-1.5"
          aria-label="Homepage"
        >
          <img
            src="/images/cbc-logo-full.png"
            alt="Clifton Bootcamp Logo"
            class="md:w-64 w-56"
          >
        </nuxt-link>
        <div class="flex lg:hidden">
          <button
            type="button"
            class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white"
            @click="mobileMenuOpen = true"
          >
            <span class="sr-only">Open main menu</span>
            <Bars3Icon
              class="h-12 w-12"
              aria-hidden="true"
            />
          </button>
        </div>
        <div class="hidden lg:flex lg:gap-x-12">
          <nuxt-link
            v-for="item in navigation"
            :key="item.name"
            :to="item.href"
            class="text-xl py-3 font-semibold leading-6 text-white"
          >
            {{ item.name }}
          </nuxt-link>
          <button
            type="button"
            class="rounded-sm bg-cbc-green px-5 py-3 text-xl font-semibold text-black uppercase hover:cbc-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cbc-green-600"
            @click="$openTrialModal()"
          >
            Book Now
          </button>
        </div>
      </nav>
      <Dialog
        class="lg:hidden"
        :open="mobileMenuOpen"
        @close="mobileMenuOpen = false"
      >
        <div class="fixed inset-0 z-10" />
        <DialogPanel class="fixed inset-y-0 right-0 z-10 overflow-y-auto px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10 bg-black">
          <div class="flex items-center justify-between">
            <nuxt-link
              to="/"
              class="-m-1.5 px-4 py-1.5 outline-none"
              @click="mobileMenuOpen = false"
            >
              <span class="sr-only">Clifton Bootcamp</span>
              <img
                src="/images/cbc-logo.png"
                alt="Clifton Bootcamp Logo"
                class="w-[72px]"
              >
            </nuxt-link>
            <button
              type="button"
              class="-m-2.5 rounded-md p-2.5 text-white"
              @click="mobileMenuOpen = false"
            >
              <span class="sr-only">Close menu</span>
              <XMarkIcon
                class="h-12 w-12"
                aria-hidden="true"
              />
            </button>
          </div>
          <nav class="mt-6 mx-4 flow-root">
            <div class="-my-6 divide-y divide-gray-500">
              <div class="space-y-2 py-6">
                <nuxt-link
                  v-for="item in navigation"
                  :key="item.name"
                  :to="item.href"
                  class="-mx-3 block rounded-lg px-3 py-2 text-xl font-semibold leading-5 text-white bg-black hover:bg-gray-500"
                  @click="mobileMenuOpen = false"
                >
                  {{ item.name }}
                </nuxt-link>
              </div>
              <div class="py-6 flex flex-row justify-center">
                <button
                  type="button"
                  class="rounded-sm bg-cbc-green px-3 py-2 text-xl font-semibold text-black uppercase hover:cbc-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cbc-green-600"
                  @click="$openTrialModal()"
                >
                  Book Now
                </button>
              </div>
            </div>
          </nav>
        </DialogPanel>
      </Dialog>
    </header>
    <main>
      <slot />
    </main>
    <trial-dialog />
    <footer class="site-footer">
      <div class="mx-auto max-w-7xl text-white text-center">
        <div class="flex justify-between items-center space-y-6 md:space-y-0 pt-8 px-8 flex-col md:flex-row">
          <div class="flex justify-center items-center md:justify-start md:items-left">
            <nuxt-link
              to="/"
              class="-m-1.5 p-1.5"
              aria-label="Homepage"
            >
              <img
                src="/images/cbc-logo-full.png"
                alt="Clifton Bootcamp Logo"
                class="w-48"
              >
            </nuxt-link>
          </div>
          <h3 class="text-3xl md:text-5xl font-bold px-8">
            Get in touch
          </h3>
          <div class="w-48 flex flex-row justify-center md:justify-end">
            <div class="w-[64px] px-2">
              <a
                href="https://www.instagram.com/cliftonbootcamp"
                rel="nofollow noopener"
                target="_blank"
                aria-label="Clifton Bootcamp Instagram Page"
              ><img
                src="/icons/SVG/instagram.svg"
                alt="Clifton Bootcamp Instagram Page"
              ></a>
            </div>
            <div class="w-[64px] px-2">
              <a
                href="https://www.facebook.com/profile.php?id=61565017485345"
                rel="nofollow noopener"
                target="_blank"
                aria-label="Clifton Bootcamp Facebook Page"
              ><img
                src="/icons/SVG/facebook.svg"
                alt="Clifton Bootcamp Facebook Page"
              ></a>
            </div>
          </div>
        </div>
        <p class="px-8 md:px-48 py-3 text-sm md:text-md">
          Clifton Downs, Bristol, BS9 1FG
        </p>
        <p class="px-8 md:px-48 py-3 text-sm md:text-xl">
          <span class="cbc-green">Tel:</span> <a href="tel:07914422713">07914 422713</a><br>
          <span class="cbc-green">Email:</span> <a href="mailto:cliftonbootcamp@outlook.com">cliftonbootcamp@outlook.com</a><br>
        </p>
        <div class="px-8 md:px-48 py-2 text-sm md:text-md">
          <p class="block md:inline-block">
            &copy; Clifton Bootcamp {{ new Date().getFullYear() }}.
          </p><p class="block md:inline-block md:pl-1">
            Registered in England and Wales, Company No: 12259238
          </p>
        </div>
        <p class="px-8 md:px-48 pb-4 text-sm md:text-md">
          <nuxt-link
            to="/terms-and-conditions"
            class="underline"
          >
            Terms
          </nuxt-link> | <nuxt-link
            to="/privacy-policy"
            class="underline"
          >
            Privacy
          </nuxt-link>
        </p>
      </div>
    </footer>
  </div>
</template>

<script setup lang="ts">
  import { Dialog, DialogPanel } from "@headlessui/vue"
  import { Bars3Icon, XMarkIcon } from "@heroicons/vue/24/outline"

  const { $openTrialModal } = useNuxtApp()

  const navigation = [
    { name: "About", href: "/about" },
    { name: "Timetable", href: "/timetable" },
    { name: "Classes", href: "/classes" },
    { name: "Pricing", href: "/pricing" },
    { name: "Contact", href: "/contact" },
  ]

  const mobileMenuOpen = ref( false )
</script>
